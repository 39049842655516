import React from "react";
import { goals, heroSection } from "../variables";
import { AirplaneTakeoff, ArrowSquareOut } from "@phosphor-icons/react";
import CustomButton from "../components/CustomButton";

type Props = {};

function Goals({}: Props) {
  return (
    <div className="flex items-center flex-col w-full p-[5%]  md:gap-[70px] sm:gap-[52px] gap-[60px] overflow-hidden bg-[#FEFFFF1A]">
      <span className=" text-[#FEFFFF] md:text-[40px] text-[28px] font-space font-bold">
        {goals.title}
      </span>
      <div className="sm:grid sm:grid-cols-2 flex flex-col gap-16 w-full items-stretch ">
        {goals.items.map((item, index) => (
          <div className="flex flex-col relative md:mb-20">
            <div
              className={`flex z-0 flex-col ${
                (index === 0 &&
                  "md:rotate-[-168deg] sm:rotate-[168deg] rotate-[168deg]") ||
                (index === 1 &&
                  "md:rotate-[168deg] sm:rotate-[-168deg] rotate-[168deg]") ||
                (index === 2 &&
                  "md:rotate-[168deg] sm:rotate-[-168deg] rotate-[168deg]") ||
                (index === 3 &&
                  "md:rotate-[-168deg] sm:rotate-[168deg] rotate-[168deg]") ||
                ""
              } w-full absolute top-0 right-0 left-0 bottom-0 border bg-[#FEFFFF1A] rounded-3xl `}
            />
            <div
              className={`flex z-0 flex-col ${
                (index === 0 &&
                  "md:rotate-[-171deg] sm:rotate-[171deg] rotate-[171deg]") ||
                (index === 2 &&
                  "md:rotate-[171deg] sm:rotate-[-171deg] rotate-[171deg]") ||
                (index === 1 &&
                  "md:rotate-[171deg] sm:rotate-[-171deg] rotate-[171deg]") ||
                (index === 3 &&
                  "md:rotate-[-171deg] sm:rotate-[171deg] rotate-[171deg]") ||
                ""
              } w-full absolute top-0 right-0 left-0 bottom-0 border bg-[#FEFFFF1A] rounded-3xl `}
            />
            <div
              className={`flex z-0 flex-col ${
                (index === 0 &&
                  "md:rotate-[-174deg] sm:rotate-[174deg] rotate-[174deg]") ||
                (index === 1 &&
                  "md:rotate-[174deg] sm:rotate-[-174deg] rotate-[174deg]") ||
                (index === 2 &&
                  "md:rotate-[174deg] sm:rotate-[-174deg] rotate-[174deg]") ||
                (index === 3 &&
                  "md:rotate-[-174deg] sm:rotate-[174deg] rotate-[174deg]") ||
                ""
              } w-full absolute top-0 right-0 left-0 bottom-0 border bg-[#FEFFFF1A] rounded-3xl `}
            />
            <div
              className={`flex z-10 md:p-[32px] p-[18px] flex-col gap-[8px] md:gap-[16px] justify-start h-full bg-[#F7C600] rounded-3xl`}
            >
              <div className="flex md:flex-row flex-col w-full md:items-center  gap-[8px] md:gap-[16px]">
                <div className="flex items-center justify-center p-[8px] bg-[#FFFEE7] rounded-[12px] md:w-[48px] w-[32px] ">
                  <AirplaneTakeoff color="#F7C600" size={"100%"} />
                </div>
                <h2 className="text-[#1A1919E5] md:text-[24px] text-[20px] font-space font-bold text-left">
                  {item.title}
                </h2>
              </div>
              <p className="flex text-[#1A1919E5] md:text-[18px] text-[14px] font-inter  text-left text-wrap flex-shrink">
                {item.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
      <CustomButton
        variant="primary"
        size="xl"
        label={goals.ctaLabel}
        iconRight={<ArrowSquareOut color="#1A1919" weight="bold" size={24} />}
        onClick={() => (window.location.href = heroSection.whatsappLink)}
      />
    </div>
  );
}

export default Goals;
