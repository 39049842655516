import React from "react";
import CustomButton from "../components/CustomButton";
import {
  ArrowSquareOut,
  CalendarBlank,
  CurrencyEur,
  MapPinLine,
} from "@phosphor-icons/react";
import Countdown from "../components/Countdown";
import { heroSection } from "../variables";

type Props = {};

function HeroSection({}: Props) {
  return (
    <div className="flex flex-col w-full relative  justify-end ">
      <picture>
        <source
          media="(max-width: 425px)"
          srcSet="/assets/images/hero_mobile.webp"
        />
        <source
          media="(max-width: 768px)"
          srcSet="/assets/images/hero_tablet.webp"
          style={{
            width: "100%",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />

        <img
          className=" md:object-cover md:object-right max-w-[1440px] w-full float-right md:h-[780px] sm:object-contain sm:object-center "
          src="/assets/images/hero.webp"
          alt="marrakech business class nabil ziani"
        />
      </picture>

      <div className="flex w-full flex-col px-[5%] md:absolute h-[100%] relative justify-center ">
        <div className="flex flex-col items-stretch  w-full md:max-w-[670px] gap-[28px] ">
          <div className="flex flex-col w-full gap-[8px]">
            <div className=" flex items-center justify-center py-[2px]  px-[8px] bg-[#f7c600] rounded-[9px] self-stretch ">
              <span className="text-[#1a1919] text-[24px] md:text-[32px] font-bold font-space leading-[38px]">
                STAGE DE 4 JOURS EN IMMERSION
              </span>
            </div>
            <div className="border border-[#feffff]/40 flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2">
              <div className="w-[24px]">
                <CalendarBlank
                  color="rgba(254, 255, 255, 0.60)"
                  weight="bold"
                  size={24}
                />
              </div>
              <span
                className="font-space text-[20px] md:text-[24px] text-bold"
                style={{ fontWeight: 700 }}
              >
                {heroSection.dateSentence}
              </span>
            </div>
            <div className="flex flex-row gap-2 self-stretch">
              <div className="border border-[#feffff]/40 flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2 flex-1">
                <div className="w-[24px]">
                  <MapPinLine
                    color="rgba(254, 255, 255, 0.60)"
                    weight="bold"
                    size={24}
                  />
                </div>
                <span
                  className="font-space  text-[20px] md:text-[24px] text-bold"
                  style={{ fontWeight: 700 }}
                >
                  {heroSection.location}
                </span>
              </div>
              <div className="border border-[#feffff]/40 flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2 flex-1">
                <div className="w-[24px]">
                  <CurrencyEur
                    color="rgba(254, 255, 255, 0.60)"
                    weight="bold"
                    size={24}
                    className="w-[24px]"
                  />
                </div>

                <span
                  className="font-space  text-[20px] md:text-[24px] text-bold"
                  style={{ fontWeight: 700 }}
                >
                  {heroSection.newPrice.toString()}€/per au lieu de{" "}
                  <span
                    style={{
                      fontWeight: 300,
                    }}
                  >
                    {heroSection.oldPrice.toString()}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <p className="font-space text-[36px] md:text-[60px] font-bold leading-[120%] drop-shadow-[5px_4px_3px_rgba(0,0,0,0.75)] ">
            COMMENT <span className="text-[#F7C600]">LANCER</span> ET{" "}
            <span className="text-[#F7C600]">REUSSIR</span> TON BUSINESS À
            MARRAKECH
          </p>
          <CustomButton
            variant="primary"
            size="xl"
            label="Je fais décoller mon business maintenant"
            iconRight={
              <ArrowSquareOut color="#1A1919" weight="bold" size={24} />
            }
            style={{ alignSelf: "flex-start" }}
            onClick={() => (window.location.href = heroSection.whatsappLink)}
          />
          <div className="flex flex-col gap-2">
            <Countdown targetDate={heroSection.startingDate} />
            {heroSection.extra && (
              <p
                className="font-space text-light text-[18px] md:text-[20px] text-[#FEFFFFB2] text-opacity-70"
                style={{ fontWeight: 500 }}
              >
                {heroSection.extra}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
