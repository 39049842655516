import { XCircle } from "@phosphor-icons/react";
import React, { useState } from "react";

type Props = {
  title: string;
  content: string;
  isOpen: string | null;
  setIsOpen: React.Dispatch<React.SetStateAction<string | null>>;
};

function CustomModal({ title, content, isOpen, setIsOpen }: Props) {
  const closeModal = () => setIsOpen(null);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-[#1A1919E5]  flex items-center justify-center z-50 "
          onClick={closeModal}
        >
          <div
            className={` max-w-[90%] flex flex-col gap-[12px] md:w-[55%] border-[#F7C600]  sm:p-[12px] md:p-[24px] p-[16px] bg-[#424242] border rounded-[28px] md:rounded-[40px]`}
          >
            <div className="flex justify-center gap-[24px] ">
              <div
                className={`flex md:h-[42px] h-[24px] min-w-[42px] w-[8%] border  rounded-[16px] bg-[#1A1919]  border-[#F7C600] `}
              ></div>
              <div
                className={`flex md:h-[42px] h-[24px] w-[26%] border  rounded-[16px] bg-[#1A1919]  border-[#F7C600] `}
              ></div>
              <div
                className={`flex md:h-[42px] h-[24px] min-w-[42px] w-[8%] border  rounded-[16px] bg-[#1A1919]  border-[#F7C600] `}
              ></div>
            </div>
            <div className="flex flex-1 items-center w-full border border-[#FEFFFF1A] bg-[#FEFFFF1A] rounded-[16px] p-[14px] flex-row justify-between">
              <span className=" font-bold font-inter md:text-[20px] text-[18px] ">
                {title}
              </span>
              <button
                onClick={closeModal}
                className="px-4 py-2  font-bold rounded-md text-[#F7C600]"
              >
                Fermer
              </button>
            </div>

            <div className="flex bg-[#1A1919E5] rounded-[16px] md:p-[32px] sm:p-[16px] p-[8px]">
              <p className="font-inter md:text-[20px] text-[18px] ">
                {content}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomModal;
