import React, { useState } from "react";
import {
  contactMe,
  contactMeYellow,
  heroSection,
  nodemailerApiUrl,
} from "../variables";
import {
  CalendarBlank,
  CalendarDots,
  CurrencyEur,
  Gift,
  MapPinLine,
  Seat,
} from "@phosphor-icons/react";
import Countdown from "../components/Countdown";
import Input from "../components/Input";
import CustomButton from "../components/CustomButton";
import { Form, Formik } from "formik";
import validationSchema from "../schemas/formSchema";

type Props = {};

function ContactMeYellow({}: Props) {
  const [status, setStatus] = useState("");
  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };

  interface FormState {
    name: string;
    email: string;
    phone: string;
  }

  const handleSendForm = async (values: any) => {
    const email = heroSection.supportEmail;
    const subject = "MARRAKECH BUSINESS CLASS - Site Web";
    const body = `Bonjour,

Je me permets de vous contacter au sujet de la session "MARRAKECH BUSINESS CLASS" prévue pour le ${heroSection.startingDate}.

Je m'appelle ${values.name} et je suis vivement intéressé(e) par cette opportunité. Vous pouvez me joindre soit par téléphone au ${values.phone}, soit par e-mail à l'adresse suivante : ${values.email}.

Dans l'attente de votre retour, je vous prie d'agréer, Madame, Monsieur, l'expression de mes salutations distinguées.

Cordialement,

${values.name}
`;
  
    const emailData = {
      recipient: "nopennoday@gmail.com",
      subject: subject,
      customer_email: values.email,
      content: body,
      username: values.name,
    };
  
    const response = await fetch(`/contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailData), // Utilisation de emailData au lieu de values
    });
    console.log("response :", response);
  
    if (response.ok) {
      setStatus("E-mail envoyé avec succès !");
    } else {
      setStatus("Échec de l'envoi de l'e-mail.");
    }
  };
  return (
    <div className="flex flex-col w-full p-[5%] bg-[#F7C600] md:gap-[70px] sm:gap-[32px] gap-[16px] overflow-hidden">
      <h2 className="font-space text-[32px] md:text-[48px] text-[#1A1919] font-bold ">
        {contactMeYellow.title}
      </h2>
      <div className="flex flex-col md:flex-row md:gap-[48px] gap-[16px] ">
        <div className="md:w-[40%] w-full rounded-[16px] md:rounded-[24px] flex flex-col md:gap-[16px] sm:gap-[12px] gap-[8px] md:p-[32px] sm:p-[20px] p-[12px] bg-[#FEFFFF1A] ">
          <span className="font-space text-[24px] md:text-[32px] text-bold text-[#1A1919] font-bold">
            {contactMe.title}
          </span>
          <div className="bg-[#FEFFFF33] flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2">
            <div className="w-[24px]">
              <CalendarBlank color="#1A1919" weight="bold" size={24} />
            </div>
            <span
              className="font-space text-[20px] md:text-[24px] text-bold text-[#1A1919] "
              style={{ fontWeight: 700 }}
            >
              {heroSection.dateSentence}
            </span>
          </div>
          <div className="bg-[#FEFFFF33] flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2 flex-1">
            <div className="w-[24px]">
              <MapPinLine color="#1A1919" weight="bold" size={24} />
            </div>
            <span
              className="font-space  text-[20px] md:text-[24px] text-bold text-[#1A1919] "
              style={{ fontWeight: 700 }}
            >
              {heroSection.location}
            </span>
          </div>
          <div className="bg-[#FEFFFF33] text- flex items-center justify-start  rounded-[9px] self-stretch p-[8px] gap-2 flex-1">
            <div className="w-[24px]">
              <CurrencyEur
                color="#1A1919"
                weight="bold"
                size={24}
                className="w-[24px]"
              />
            </div>

            <span
              className="font-space  text-[20px] md:text-[24px] text-bold text-[#1A1919] "
              style={{ fontWeight: 700 }}
            >
              {heroSection.newPrice.toString()}€/per au lieu de{" "}
              <span
                style={{
                  fontWeight: 300,
                }}
              >
                {heroSection.oldPrice.toString()}
              </span>
            </span>
          </div>
          <Countdown
            targetDate={heroSection.startingDate}
            wrapperStyle="bg-[#FEFFFF33] text-zinc-950 "
          />
        </div>
        <div className="md:w-auto md:flex-grow w-full rounded-[16px] md:rounded-[24px] flex flex-col md:gap-[16px] sm:gap-[12px] gap-[8px] md:p-[32px] sm:p-[20px] p-[12px] bg-[#FFFEE7] ">
          <div className="flex flex-col gap-[8px]">
            <span className="font-space text-[24px] md:text-[32px] text-bold text-[#1A1919] font-bold">
              {contactMeYellow.formTitle}
            </span>
            <span className="font-inter text-[16px] md:text-[18px] text-bold text-[#1A191980] ">
              {contactMeYellow.formHelper}
            </span>
          </div>

          <div className="flex flex-col">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSendForm}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isValid,
                isSubmitting,
                errors,
                touched,
              }) => {
                console.log("errors :", errors);
                console.log("touched :", touched);

                return (
                  <Form className="flex flex-col" onSubmit={handleSubmit}>
                    {contactMeYellow.inputs.map((input, index) => (
                      <Input
                        key={index}
                        label={input.label}
                        name={input.name}
                        value={values[input.name as keyof FormState]}
                        placeholder={input.placeholder}
                        onChange={handleChange}
                        error={errors[input.name as keyof FormState]}
                      />
                    ))}
                    <CustomButton
                      variant="primary"
                      size="xl"
                      label={contactMeYellow.ctaLabel}
                      iconLeft={
                        <Seat
                          color={
                            !isValid || isSubmitting
                              ? "rgb(107 114 128)"
                              : "#1A1919"
                          }
                          weight="bold"
                          size={24}
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      style={{ alignSelf: "flex-end" }}
                      tailwindStyle="w-full sm:w-auto"
                      disabled={!isValid || isSubmitting}
                    />
                    {status && <p>{status}</p>}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      {/* <div className="md:w-auto md:flex-grow w-full items-start rounded-[16px] md:rounded-[24px] flex flex-col md:gap-[16px] sm:gap-[12px] gap-[8px] md:p-[32px] sm:p-[20px] p-[12px] bg-[#E5BD1C] ">
        <div className="flex flex-row gap-[8px] p-[8px] bg-[#E82817] rounded-[8px] items-center w-full sm:w-auto">
          <div className="flex md:w-[32px] sm:w-[32px] w-[32px]">
            <Gift size={"100%"} color={"#FEFFFF"} weight="bold" />
          </div>
          <span className="font-space text-[14px] md:text-[16px] text-bold text-[#FEFFFF] font-bold">
            {contactMeYellow.bottomSection.title}
          </span>
        </div>
        <span className="font-space text-[24px] md:text-[32px] text-bold text-[#1A1919] font-bold">
          {contactMeYellow.bottomSection.desc}
        </span>
        <CustomButton
          variant="black"
          size="xl"
          label={contactMeYellow.bottomSection.ctaLabel}
          iconLeft={<CalendarDots color="#FEFFFF" weight="bold" size={24} />}
          onClick={() => (window.location.href = heroSection.whatsappLink)}
          tailwindStyle=" w-full sm:w-auto "
        />
      </div> */}
    </div>
  );
}

export default ContactMeYellow;
