import React, { useState } from "react";
import { team } from "../variables";
import {
  FacebookLogo,
  Gift,
  InstagramLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import ProfileCard from "../components/ProfileCard";

type Props = {};
type Profil = {
  name: string;
  role: string;
  yearsOfExperience: number;
  experience: string;
  profile: string;
  instagramTag: string;
  instagramLink: string;
  facebookLink: string;
  youtubeLink: string;
  isFavorite: boolean;
  descGift: string;
  whatsappLink: string;
};
function TeamSection({}: Props) {
  const favoriteProfile =
    team.profiles.find((p) => p.isFavorite === true) || team.profiles[0];
  return (
    <div className="flex flex-col w-full p-[5%] gap-[70px]">
      <span className=" text-[#FEFFFF] md:text-[40px] text-[28px] font-space font-bold">
        {team.title}
      </span>
      <div className="flex flex-col md:flex-row gap-[24px] md:gap-[32px] w-full">
        <ProfileCard profil={favoriteProfile} />
        <div className="flex md:flex-shrink md:flex-grow flex-col md:items-stretch gap-[24px]">
          {team.profiles
            .filter((p) => !p.isFavorite)
            .map((member, index) => {
              return (
                <ProfileCard profil={member} wrapperStyle="md:w-full flex-1" />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default TeamSection;
