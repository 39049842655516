import {
  CalendarBlank,
  MapPinLine,
  NumberSquareFour,
  NumberSquareOne,
  NumberSquareThree,
  NumberSquareTwo,
} from "@phosphor-icons/react";

export const nodemailerApiUrl = "http://192.168.1.33:2002/contact-us";

export const heroSection = {
  dateSentence: "Du 09 au 12 décembre 2024, de 9:00 a 17:00",
  startingDate: "09/12/2024", //Accepted format : dd/mm/yyy
  location: "M Avenue, Marrakech",
  newPrice: 950,
  oldPrice: 1750,
  extra: "* Petit déjeuner & pause gourmande inclus pour un confort optimal.",
  whatsappLink: "https://wa.me/message/Q3S7PIQFUTWKO1",
  supportEmail: "test@test.com",
};
export const partners = [
  "gontranCherrier-logo.svg",
  "kiamTech-logo.svg",
  "mTech-logo.svg",
  "mAvenue-logo.svg",
]; // Merci de mettre les logos de vos partenaires dans le dossier public/assets/icons

export const advantages = {
  title: "Avec la Marrakech Business Class, tu vas apprendre  à :",
  items: [
    {
      id: 1,
      advantageTitle: "Définir clairement ton business",
      descTitle: "Un business model solide",
      desc: `Grâce au Business Model Canvas, tu sortiras du stage
        avec une feuille de route claire pour ton projet. 
        Fini les hésitations, tu sauras
        précisément où tu vas.`,
      Icon: ({ color }) => (
        <NumberSquareOne size="100%" weight="duotone" color={color} />
      ),
    },
    {
      id: 2,
      advantageTitle: "Transformer ton mindset",
      descTitle: "Un mindset d'acier",
      desc: `A travers ces 4 jours, tu apprendras à surmonter les obstacles, à gérer la pression et à rester concentré sur tes objectifs. Le mental est la clé pour faire face à toutes les étapes de ton parcours entrepreneurial.`,
      Icon: ({ color }) => (
        <NumberSquareTwo size="100%" weight="duotone" color={color} />
      ),
    },
    {
      id: 3,
      advantageTitle: "Convaincre et vendre",
      descTitle: "Des compétences de vente",
      desc: `Savoir vendre est essentiel pour faire vivre ton entreprise. Tu apprendras des techniques concrètes pour convaincre tes clients et faire décoller ton chiffre d'affaires.`,
      Icon: ({ color }) => (
        <NumberSquareThree size="100%" weight="duotone" color={color} />
      ),
    },
    {
      id: 4,
      advantageTitle: "Devenir visible auprès de tes clients",
      descTitle: "Une stratégie marketing efficace",
      desc: `En maîtrisant les clés du marketing digital, tu
        sauras comment rendre visible ton entreprise sans dépenser des fortunes.
        Attire les bons clients et fais grandir ta marque.`,
      Icon: ({ color }) => (
        <NumberSquareFour size="100%" weight="duotone" color={color} />
      ),
    },
  ],
};

export const team = {
  title: "Ton équipage à bord : aux commandes de ton succès !",
  profiles: [
    {
      name: "Nabil Ziani",
      role: "Le Commandant de Bord",
      yearsOfExperience: 16,
      experience:
        "Entrepreneur depuis l’âge de 20 ans, j’ai eu la chance de vivre et de développer des projets sur trois continents.Depuis plus de 10 ans, j’ai fondé et géré de nombreuses entreprises : agences de coaching scolaire, centre d’aquabiking, boutiques e-commerce, boulangerie à domicile, boutique physique de maroquinerie, centre d’appel, conciergerie, agence de marketing… Aujourd’hui, je me concentre sur le développement international de la franchise WATERKARTING, un concept unique que je commercialise à travers le monde. Certifié Scrum Master et créateur de la méthode « NoPenNoDay », j’accompagne mes clients dans l’atteinte rapide de leurs objectifs.",
      profile:
        "« Qui donne reçoit ! » La richesse de mon parcours réside dans le partage. Chaque aventure entrepreneuriale m’a permis d’accumuler une expérience précieuse que je transmets à mes clients. Je leur propose un accompagnement concret et adapté, basé sur mon vécu terrain, pour faire décoller leur projet avec succès.",
      instagramTag: "@nabilziani_",
      instagramLink:
        "https://www.instagram.com/nabilziani_?igsh=cTJueXk5aXhpaWx6&utm_source=qr",
      facebookLink:
        "https://www.facebook.com/profile.php?id=61550760876440&mibextid=kFxxJD",
      youtubeLink: "https://youtube.com/@coachnabilziani?si=5yPzMwJZRxlZfB4K",
      linkedinLink: "https://www.linkedin.com/in/nopennoday/",
      isFavorite: true,
      descGift:
        "Profite de ton appel gratuit directement avec moi pour échanger",
      whatsappLink: "",
      imageName: "nabil.webp",
    },
    {
      name: "Hakima Mommen",
      role: "Pilote en Mindset",
      yearsOfExperience: 18,
      experience:
        "Hakima Mommen accompagne les entrepreneurs dans l'amélioration de leur santé et leur bien-être global. Diététicienne certifiée et nutrithérapeute, elle combine ses 18 années d'expérience pour offrir des solutions completes. Formée en audit nutritionnel, psycho-neuronutrition, et alignement neuro-émotionnel, elle aide à identifier les besoins nutritionnels et émotionnels de chacun, créant un équilibre durable pour une santé optimale et un épanouissement personnel.",
      profile:
        "Hakima Mommen a passé plus de dix ans à enseigner la nutrition à Bruxelles avant de s'orienter vers l'entrepreneuriat. Désirant plus de liberté pour explorer son potentiel, elle s'est installée à Marrakech, où elle propose une approche unique. Formée en nutrithérapie et coaching intuitif, elle a développé une méthode innovante qui combine la nutrition, la neuro-émotion et le bien-être psychologique, guidant ses clients vers un équilibre personnel et professionnel.",
      instagramTag: "@hakima_mommen",
      instagramLink:
        "https://www.instagram.com/hakima_mommen?igsh=MTRxbW01dDdpODVw",
      facebookLink: "",
      youtubeLink: "",
      isFavorite: false,
      imageName: "hakima.webp",
    },
    {
      name: "Leyla",
      role: "Pilote en Force de vente",
      yearsOfExperience: 20,
      experience:
        "Experte en développement commercial depuis plus de 20 ans, Leyla accompagne les entrepreneurs, les entreprises et les équipes de vente en les formant et coachant à la vente, la prospection, la négociation et le closing. Rodée à l’exercice, elle vous en révèle les moindres petits secrets.",
      profile:
        "Leyla est une entrepreneuse dans l’âme, sa force de caractère ne fait plus qu’un avec sa force de vente. On ne sait plus si c’est une compétence acquise ou un don iné. L’art de la vente est comme une seconde nature chez elle.",
      instagramTag: "@leyla_coachbusiness",
      instagramLink:
        "https://www.instagram.com/leyla_coachbusiness?igsh=d2h6Z3prc3oxdG1s",
      facebookLink: "",
      youtubeLink: "",
      isFavorite: false,
      imageName: "laila.webp",
    },
    {
      name: "Houda",
      role: "Pilote en Marketing digital",
      yearsOfExperience: 10,
      experience:
        "Houda accompagne ses clients dans le développement de leur visibilité et notoriété en ligne grâce à son expertise en marketing digital. En combinant stratégie ciblée, contenus engageants et gestion pointue des réseaux sociaux, elle les aide à vendre davantage et à transformer leur présence en ligne en une véritable force de croissance.",
      profile:
        "Houda ne vous le dira jamais, mais ses clients l’appellent la Queen d’Instagram, sauf qu’en réalité elle est leur ombre quand ils brillent sur les réseaux. Sa passion va au delà d’une simple prise de photo ou vidéo. Pour elle, la réussite d’une publication se trouve dans l’authenticité ! Elle gagne la confiance de ses clients en leur donnant plus de visibilité tout en restant fidèle à leur personnalité.",
      instagramTag: "@houda.cm",
      instagramLink:
        "https://www.instagram.com/houda.cm?igsh=MW0wZmRlcjA3emxqMQ==",
      facebookLink: "",
      youtubeLink: "",
      isFavorite: false,
      imageName: "houda.webp",
    },
  ],
};

export const testimonies = {
  title: "Ce que nos passagers disent de nous",
  numberOfImages: 15, //C est le nombre des images a mettre dans le dossier public/assets/images/testimonies avec une nomenclature testimoniy[index].webp
};

export const contactMe = {
  title: "🚨15 sièges seulement de disponible  🚨",
  desc: `Cet évènement est réservé à 15 personnes pour une expérience VIP en petit comité. Ne manque pas cette opportunité de faire partie de l'élite du programme "Business Class". Les places s’envolent rapidement !`,
  ctaLabel: "Je fais décoller mon Business",
  banners: [
    {
      label: "Date",
      value: "Du 09 au 12 décembre 2024",
      icon: <CalendarBlank color="#878787" weight="bold" size={24} />,
    },
    {
      label: "Lieu",
      value: "M Avenue, Marrakech",
      icon: <MapPinLine color="#878787" weight="bold" size={24} />,
    },
  ],
};

export const goals = {
  title: "A la fin de ton voyage, tu seras capable de :",
  ctaLabel: "Je fais décoller mon business maintenant",
  items: [
    {
      title: "Equilibrer ta vie-pro avec Bien-être",
      desc: "Commence par le mental. Comment adopter une mentalité de gagnant face aux défis de l'entrepreneuriat. Gère ton stress, maintiens ton équilibre entre vie pro et perso, et donne à ton corps toute l’energie dont il a besoin pour rester productif.",
    },
    {
      title: "Bénéficier de ressources premium",
      desc: "Structure ton idée d’entreprise avec le Business Model Canvas. À la fin de ce stage, tu repartiras avec un plan détaillé et concret de ton business, prêt à être mis en œuvre.",
    },
    {
      title: "Optimiser tes ventes et ta stratégie digitale",
      desc: "Apprends à vendre efficacement, même si tu n’as jamais vendu auparavant. Découvre des techniques éprouvées pour convaincre tes prospects et transformer tes leads en clients fidèles. Crée une stratégie digitale adaptée à ton marché et apprends à attirer les bons clients tout en optimisant ton budget.",
    },
    {
      title: "Rejoindre un réseau exclusif",
      desc: "Ce réseau te permettra de bénéficier d'un soutien continu, d'échanger des idées, et de recevoir des conseils pratiques pour t'accompagner dans le développement de ton projet bien après le stage.",
    },
  ],
};

export const fAndQ = {
  title: "Questions Fréquentes Des voyageurs",
  ctaLabel: "J’ai + des questions",
  items: [
    {
      q: "Je ne suis pas entrepreneur, est-ce que ce stage est fait pour moi ?",
      r: "Oui, ce stage s’adresse à tous ceux qui souhaitent se lancer dans l'entrepreneuriat, que tu sois déjà entrepreneur ou non. Même sans expérience, tu apprendras les bases indispensables pour démarrer un projet et acquérir les compétences clés.",
    },
    {
      q: "Je n’ai pas encore d’idée de business, est-ce un problème ?",
      r: "Pas du tout ! Ce stage est aussi fait pour t’aider à trouver et affiner ton idée de projet. Tu seras guidé pour identifier des opportunités, explorer des idées, et les transformer en un business concret.",
    },
    {
      q: "J'ai déjà lancé mon business, est-ce que ce stage peut encore m'aider ?",
      r: "Absolument ! Ce stage va te permettre d'améliorer ta stratégie, renforcer ton business model, et acquérir des compétences essentielles comme la vente et le marketing digital pour faire grandir ton entreprise.",
    },
    {
      q: "Est-ce que je serai accompagné après le stage ?",
      r: "Oui, tu feras partie d’un réseau exclusif d'entrepreneurs qui te permettra de rester en contact avec des experts et d'autres stagiaires pour t’aider à avancer même après la fin du stage.",
    },
  ],
};

export const contactMeYellow = {
  title: "Dernier rappel avant l’embarquement !",
  formTitle: "La Business class rime avec Réactivité !",
  formHelper: "Entre tes coordonnées pour être contacté",
  ctaLabel: "Je veux être contacté",
  inputs: [
    {
      label: "Nom Complet",
      placeholder: "Nom Prénom",
      name: "name",
      onChange: "setName",
    },
    {
      label: "Email",
      placeholder: "email@exemple.com",
      name: "email",
      onChange: "setEmail",
    },
    {
      label: "Numéro de Téléphone",
      placeholder: "06 60 60 60 60",
      name: "phone",
      onChange: "setPhone",
    },
  ],
  bottomSection: {
    title: "Profite de ton cadeau maintenant",
    desc: "Appelle-moi pour échanger  et réserver ton siège !",
    ctaLabel: "Je veux profiter de mon appel offert",
  },
};

export const footer = {
  instagramLink:
    "https://www.instagram.com/nabilziani_?igsh=cTJueXk5aXhpaWx6&utm_source=qr",
  facebookLink:
    "https://www.facebook.com/profile.php?id=61550760876440&mibextid=kFxxJD",
  youtubeLink: "https://youtube.com/@coachnabilziani?si=5yPzMwJZRxlZfB4K",
  linkedinLink: "https://www.linkedin.com/in/nopennoday/",
};
