import HeroSection from "./containers/HeroSection";
import PartnersSection from "./containers/PartnersSection";
import AdvantagesSection from "./containers/AdvantagesSection";
import TeamSection from "./containers/TeamSection";

import ReviewsSection from "./containers/ReviewsSection";
import ContactMe from "./containers/ContactMe";
import Goals from "./containers/Goals";
import FAndQ from "./containers/FAndQ";
import ContactMeYellow from "./containers/ContactMeYellow";
import Footer from "./containers/Footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="flex flex-col items-center w-full min-h-[100vh] mx-[auto] max-w-[1440px]">
      <Helmet>
        <meta
          name="description"
          content="Rejoignez la Marrakech Business Class du 21 au 24 octobre 2024 à M Avenue, Marrakech. Un programme intensif pour entrepreneurs, conçu pour transformer votre mindset, renforcer vos compétences en business, et développer une stratégie marketing performante. Places limitées à 15 participants pour une expérience VIP. Ne manquez pas cette occasion unique de faire décoller votre entreprise !"
        />
        <meta
          name="keywords"
          content="
    Marrakech Business Class, entrepreneuriat, mindset, stratégie marketing, vente, business, M Avenue, 2024, formation,
    Formation entrepreneur Marrakech, Coaching business Marrakech, Stage entrepreneuriat Marrakech, Développement personnel Marrakech,
    Accompagnement entrepreneurial, Business coach Maroc, Masterclass entrepreneuriat Maroc, Formation mindset entrepreneur,
    Coaching professionnel Marrakech, Formation gestion d’entreprise, Créer son entreprise à Marrakech, Booster son business au Maroc,
    Développer ses compétences entrepreneuriales, Formation immersion entrepreneurs, Coaching en stratégie d’entreprise, 
    Productivité et entrepreneuriat, Coaching mindset et productivité, Améliorer ses compétences business, Lancer un business au Maroc, 
    Formation 4 jours pour entrepreneurs
  "
        />
        <title>
          Marrakech Business Class 2024 - Formation pour entrepreneurs
        </title>
      </Helmet>

      <img src="/assets/icons/logo.svg" className=" w-[40%] my-4" alt="logo" />
      <HeroSection />
      <PartnersSection />
      <AdvantagesSection />
      <TeamSection />
      <ReviewsSection />
      <ContactMe />
      <Goals />
      <FAndQ />
      <ContactMeYellow />
      <Footer />
    </div>
  );
}

export default App;
//
