import React, { useState } from "react";
import { advantages } from "../variables";
import { NumberSquareOne } from "@phosphor-icons/react";

type Props = {};

function AdvantagesSection({}: Props) {
  const [selectedTab, setSelectedTab] = useState(1);
  const handleTabSelect = (id: number) => {
    setSelectedTab(id);
  };
  return (
    <div className="flex flex-col w-full p-[5%] gap-[70px]">
      <span className=" text-[#FEFFFF] md:text-[40px] text-[28px] font-space font-bold">
        {advantages.title}
      </span>
      <div className="flex flex-col gap-[24px]">
        {advantages.items.map(
          ({ id, advantageTitle, descTitle, desc, Icon }, index) => {
            const headerStyle =
              selectedTab === id
                ? `bg-gradient-yellow text-[#1A1919E5]`
                : ` bg-[#fff] bg-opacity-10 text-[#FEFFFF] border-neutral-600`;
            return (
              <div className="flex flex-col gap-[16px]" key={index}>
                <div
                  onClick={() => handleTabSelect(id)}
                  className={`flex flex-row w-full md:p-[24px] p-[12px] rounded-xl border items-center md:gap-[24px] gap-[12px] font-space font-bold sm:text-[20px] md:text-[24px] ${headerStyle}`}
                >
                  <div className=" w-[40px]  md:w-[48px] lg:w-[56px] aspect-square">
                    <Icon color={selectedTab === id ? "#1A1919" : "#FEFFFF"} />
                  </div>
                  <div className="flex-grow flex-wrap flex-shrink text-wrap">
                    <span>{advantageTitle}</span>
                  </div>
                </div>
                {selectedTab === id && (
                  <div className="flex flex-col w-full p-[12px] md:p-[24px] rounded-xl border border-neutral-600 gap-[24px] ">
                    <span className="font-space text-[18px] md:text-[20px] ">
                      {descTitle}
                    </span>
                    <p className="font-space text-[18px] md:text-[20px] text-[#FEFFFF] text-opacity-60">
                      {desc}
                    </p>
                  </div>
                )}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default AdvantagesSection;
