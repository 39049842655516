import * as Yup from "yup";

const emailRegex =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|fr|kr|net|us|info|biz|me|name|cc|org|tv|ws|mobi|de|am|fm|ca|bz|com.bz|net.bz|es|co|se|la)$/;
const nameRegex = /^[a-zA-ZÀ-ÿ\s]+$/;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Le nom est requis")
    .matches(
      nameRegex,
      "Le nom doit être composé uniquement de lettres ou de caractères spéciaux"
    ),
  email: Yup.string()
    .trim("Ce champ ne peut pas inclure d'espaces en début et en fin.")
    .strict(true)
    .required("Ce champ est requis")
    .matches(emailRegex, "Veuillez entrer une adresse e-mail valide"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Le numéro doit contenir uniquement des chiffres")
    .min(10, "Le numéro doit comporter au moins 10 chiffres")
    .required("Le numéro de téléphone est requis"),
});

export default validationSchema;
