import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import { useRef } from "react";
import { testimonies } from "../variables";

function VariableWidth() {
  const sliderRef = useRef<Slider | null>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const settings = {
    className: "slider ",
    dots: false,
    infinite: true,
    // centerMode: true,
    // VariableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="slider-container ">
        <Slider {...settings} ref={sliderRef}>
          {Array.from({ length: testimonies.numberOfImages }, (_, i) => (
            <div className="rounded-[16px] overflow-hidden">
              <img
                key={i}
                src={`/assets/images/testimonies/testimony${i + 1}.webp`}
                alt={`Testimony ${i + 1}`}
                style={{ objectPosition: "center" }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="flex flex-row gap-6 p-2">
        <button className="button" onClick={previous}>
          <ArrowLeft color="#F7C600" size={20} weight="bold" />
        </button>
        <button className="button" onClick={next}>
          <ArrowRight color="#F7C600" size={20} weight="bold" />
        </button>
      </div>
    </div>
  );
}
export default VariableWidth;
