import React, { useState, useEffect } from "react";

interface CountdownProps {
  targetDate: string; // Format : 'jj/mm/yyyy'
  wrapperStyle?: string;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate, wrapperStyle }) => {
  const calculateTimeLeft = (): number => {
    const [day, month, year] = targetDate.split("/").map(Number);
    const targetTimestamp = new Date(year, month - 1, day).getTime(); // Convertit la date en timestamp
    const now = new Date().getTime();
    return Math.max(targetTimestamp - now, 0); // Retourne le temps restant en millisecondes
  };

  const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [targetDate]);

  // Calculer les jours, heures, minutes et secondes restants
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return (
    <div
      className={`flex self-stretch flex-row items-center justify-center w-full p-[12px] bg-[#1A1919] bg-opacity-80 border  border-[#feffff]/40 rounded-[9px] text-[#F7C600] font-space text-[36px] md:text-[60px] text-bold ${wrapperStyle}`}
      style={{ fontWeight: 700 }}
    >
      {timeLeft > 0 ? (
        <p>
          {days > 0 && `${days}J - `} {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </p>
      ) : (
        <p>00:00:00</p>
      )}
    </div>
  );
};

export default Countdown;
