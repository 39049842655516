import React from "react";
import VariableWidth from "../components/Carrousel";
import VideoComponent from "../components/VideoComponent";
import { testimonies } from "../variables";

type Props = {};

function ReviewsSection({}: Props) {
  return (
    <div className="flex sm:flex-row items-center flex-col w-full p-[5%]  md:gap-[70px] sm:gap-[32px] gap-[16px] overflow-hidden">
      <span className="block sm:hidden text-[#FEFFFF] md:text-[40px] text-[28px] font-space font-bold">
        Ce que nos passagers disent de nous
      </span>
      <VideoComponent
        src="/assets/videos/testimonies.mp4"
        poster="/assets/images/thumbnail.webp"
        autoplay={false}
        loop={true}
        muted={false}
      />
      <div className="flex flex-col flex-grow flex-shrink sm:w-[350px] w-[100%] justify-center">
        <span className="sm:block hidden text-[#FEFFFF] md:text-[40px] text-[28px] font-space font-bold">
          {testimonies.title}
        </span>
        <VariableWidth />
      </div>
    </div>
  );
}

export default ReviewsSection;
