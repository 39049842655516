import {
  FacebookLogo,
  Gift,
  InstagramLogo,
  LinkedinLogo,
  WhatsappLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import React, { useState } from "react";
import CustomModal from "./CustomModal";

type Profil = {
  name: string;
  role: string;
  yearsOfExperience: number;
  experience: string;
  profile: string;
  instagramTag: string;
  instagramLink?: string | undefined;
  facebookLink?: string | undefined;
  youtubeLink?: string | undefined;
  linkedinLink?: string | undefined;
  isFavorite?: boolean;
  descGift?: string | undefined;
  whatsappLink?: string | undefined;
  imageName?: string | undefined;
};

type Props = {
  profil: Profil; // Ensure the type is correct
  wrapperStyle?: string;
};

function ProfileCard({ profil, wrapperStyle = "" }: Props) {
  const [isOpen, setIsOpen] = useState<string | null>(null);

  return (
    <div
      className={`flex flex-col gap-[12px] md:w-[55%] ${wrapperStyle} ${
        profil.isFavorite ? " border-[#F7C600]" : "border-[#FEFFFF66]"
      } sm:p-[12px] md:p-[24px] p-[16px] bg-[#424242] border rounded-[28px] md:rounded-[40px]`}
    >
      <div className="flex justify-center gap-[24px]">
        <div
          className={`flex md:h-[42px] h-[24px] min-w-[42px] w-[8%] border  rounded-[16px] bg-[#1A1919] ${
            profil.isFavorite ? " border-[#F7C600]" : "border-[#FEFFFF66]"
          }`}
        ></div>
        <div
          className={`flex md:h-[42px] h-[24px] w-[26%] border  rounded-[16px] bg-[#1A1919] ${
            profil.isFavorite ? " border-[#F7C600]" : "border-[#FEFFFF66]"
          }`}
        ></div>
        <div
          className={`flex md:h-[42px] h-[24px] min-w-[42px] w-[8%] border  rounded-[16px] bg-[#1A1919] ${
            profil.isFavorite ? " border-[#F7C600]" : "border-[#FEFFFF66]"
          }`}
        ></div>
      </div>
      <div
        className={`flex  ${
          profil.isFavorite ? `md:flex-col` : `flex-row flex-grow`
        } sm:flex-row flex-col gap-[12px] flex-grow `}
      >
        <div
          className={`overflow-hidden relative border border-[#FEFFFF1A] rounded-[16px] flex ${
            profil.isFavorite
              ? "flex-grow w-full"
              : "flex-1 sm:aspect-square aspect-square"
          }`}
        >
          <img
            src={`/assets/images/${profil.imageName}`}
            alt=""
            className={`object-cover ${profil.isFavorite ? "" : "absolute "}`}
          />
        </div>

        <div
          className={`flex border border-[#FEFFFF1A] rounded-[16px] ${
            profil.isFavorite ? "w-full " : "flex-1"
          } `}
        >
          <div className="flex flex-1 flex-col justify-center p-[12px]">
            <span
              className={` font-space  font-bold ${
                profil.isFavorite
                  ? "md:text-[32px] sm:text-[24px] text-[20px]"
                  : "md:text-[24px] sm:text-[24px] text-[20px]"
              }`}
            >
              {profil?.name}
            </span>
            <span
              className={` font-inter md:text-[14px] sm:text-[20px] text-[12px] text-[#FEFFFF99] ${
                profil.isFavorite ? "" : ""
              }`}
            >
              {profil?.instagramTag}
            </span>
            <span
              className={` font-inter md:text-[20px] text-[18px] text-[#F7C600]  font-bold ${
                profil.isFavorite ? "" : ""
              }`}
            >
              {profil?.role}
            </span>
            <span
              className={` font-inter md:text-[20px] text-[18px] text-[#FEFFFFCC]  font-bold ${
                profil.isFavorite ? "" : ""
              }`}
            >
              {profil?.yearsOfExperience} ans d'expérience
            </span>
            <div className={`flex flex-row gap-[18px]`}>
              {profil?.facebookLink && (
                <a
                  className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
                  href={profil?.facebookLink}
                >
                  <FacebookLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
                </a>
              )}
              {profil?.instagramLink && (
                <a
                  className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
                  href={profil?.instagramLink}
                >
                  <InstagramLogo
                    size={"100%"}
                    color={"#FEFFFF"}
                    weight="bold"
                  />
                </a>
              )}
              {profil?.youtubeLink && (
                <a
                  className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
                  href={profil?.youtubeLink}
                >
                  <YoutubeLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
                </a>
              )}
              {profil?.linkedinLink && (
                <a
                  className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
                  href={profil?.linkedinLink}
                >
                  <LinkedinLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col gap-[12px]">
        <div className="flex flex-1 items-center w-full border border-[#FEFFFF1A] bg-[#FEFFFF1A] rounded-[16px] p-[14px] flex-row justify-between">
          <span className="font-space md:text-[20px] text-[18px] font-bold ">
            Expertise
          </span>
          <span
            className="font-space md:text-[20px] sm:text-[18px] text-[14px] font-bold text-[#F7C600] "
            onClick={() => setIsOpen("experiance")}
          >
            Voir
          </span>
        </div>
        <div className="flex flex-1 items-center w-full border border-[#FEFFFF1A] bg-[#FEFFFF1A] rounded-[16px] p-[14px] flex-row justify-between">
          <span className="font-space md:text-[20px] text-[18px] font-bold ">
            Profil
          </span>
          <span
            className="font-space md:text-[20px] sm:text-[18px] text-[14px] font-bold text-[#F7C600] "
            onClick={() => setIsOpen("profile")}
          >
            Voir
          </span>
        </div>
      </div>
      {/* {profil.isFavorite && (
        <div className="flex w-full sm:flex-row flex-col items-center bg-[#F3F3F3] rounded-[16px] md:p-[16px]  p-[12px] gap-[12px]">
          <div className="flex md:flex-col gap-[12px] flex-row">
            <div className="flex md:w-[56px] sm:w-[48px] w-[70px]">
              <Gift size={"100%"} color={"#424242"} weight="bold" />
            </div>
            <span className="font-space md:text-[20px] text-[18px] font-bold text-[#424242]">
              {profil?.descGift}
            </span>
          </div>
          <a
            className="flex justify-center sm:aspect-square md:w-[56px] w-full md:p-[16px] sm:w-[48px] sm:p-[14px]  p-[14px] bg-[#1A1919] rounded-xl mt-1"
            href={profil?.whatsappLink}
          >
            <WhatsappLogo
              size={"100%"}
              color={"#FEFFFF"}
              weight="bold"
              className="sm:flex hidden"
            />
            <span className="sm:hidden flex">
              Je veux profiter de mon appel offert
            </span>
          </a>
        </div>
      )} */}
      <CustomModal
        title={
          isOpen === "experiance"
            ? "Expertise"
            : isOpen === "profile"
            ? "Profil"
            : ""
        }
        content={
          isOpen === "experiance"
            ? profil.experience
            : isOpen === "profile"
            ? profil.profile
            : ""
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}

export default ProfileCard;
