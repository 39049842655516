import { Play, PlayCircle } from "@phosphor-icons/react";
import React, { useRef, useState } from "react";

type VideoProps = {
  src: string;
  poster?: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  width?: number;
  height?: number;
};

const VideoComponent: React.FC<VideoProps> = ({
  src,
  poster,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
  width = 640,
  height = 360,
}) => {
  const [isPlaying, setIsPlaying] = useState(autoplay);
  const videoRef = useRef<HTMLVideoElement>(null);
  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  return (
    <div
      style={{ aspectRatio: 618 / 1080 }}
      className="relative sm:w-[40%] w-full rounded-[16px] overflow-hidden border border-[#F7C600] object-cover"
    >
      <video
        ref={videoRef}
        width={width}
        height={height}
        controls={controls}
        autoPlay={autoplay}
        loop={loop}
        muted={muted}
        poster={poster} // Poster is an image shown before the video plays
        className="object-cover"
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <button
          onClick={togglePlay}
          className=" flex justify-center items-center bg-[#F7C600] w-[20%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 rounded-full aspect-square shadow-md"
        >
          <Play color="#343434" weight="fill" size={"80%"} />
        </button>
      )}
    </div>
  );
};

export default VideoComponent;
