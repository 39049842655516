import React, { FormEvent } from "react";

type ButtonVariant =
  | "primary"
  | "black"
  | "secondary"
  | "tertiary"
  | "secondaryGrey"
  | "tertiaryGrey";
type ButtonSize = "xxl" | "xl" | "lg" | "md" | "sm";

interface ButtonProps {
  variant: ButtonVariant;
  size: ButtonSize;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onClick: (
    event: React.MouseEvent<HTMLDivElement> | FormEvent<HTMLFormElement>
  ) => void;
  tailwindStyle?: string;
}

const CustomButton: React.FC<ButtonProps> = ({
  variant,
  size,
  label,
  disabled = false,
  style,
  iconLeft,
  iconRight,
  onClick,
  tailwindStyle,
}) => {
  const baseClasses = `flex items-center justify-center gap-2 font-bold rounded-[12px] transition-all duration-200 ${
    disabled ? "cursor-not-allowed" : "cursor-pointer"
  }`;

  const variantClasses = {
    primary: !disabled
      ? "bg-gradient-to-b from-[#FFED41] to-[#F7C600] hover:from-[#F7C600] hover:to-[#F7C600] hover:text-[rgba(26, 25, 25, 0.80)] text-[#1A1919]"
      : "bg-gray-200 text-gray-500",
    black: !disabled
      ? "bg-gradient-to-b from-[#1A1919] to-[#1A1919] hover:from-[#1A1919] hover:to-[#1A1919] hover:text-[#FEFFFF] text-[#FEFFFF]"
      : "bg-gray-200 text-gray-500",
    secondary: !disabled
      ? " bg-[#1A1919] hover:bg-[#5E5E5E] text-[#FEFFFF]"
      : "bg-transparent border-none text-gray-400",
    secondaryGrey: !disabled
      ? "border border-gray-400 text-gray-400 hover:bg-gray-100"
      : "border border-gray-200 text-gray-300",
    tertiary: !disabled
      ? "text-[#F7C600] hover:text-[#E5BD1C] "
      : "text-gray-400",
    tertiaryGrey: !disabled
      ? "text-gray-400 hover:bg-gray-100"
      : "text-gray-300",
  };

  const sizeClasses = {
    xxl: "h-14 text-lg px-4",
    xl: "h-[56px] text-base px-4 md:text-[18px] text-[14px]",
    lg: "h-[48px] text-base px-4 md:text-[16px] text-[12px]",
    md: "h-10 md:text-sm px-3 text-[10px]",
    sm: "h-[44px] text-base px-4 text-[14px]",
  };

  return (
    <div
      className={` hover:animate-pulse font-inter ${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${tailwindStyle}`}
      onClick={(e) => !disabled && onClick(e)}
      style={style}
    >
      {iconLeft && <span>{iconLeft}</span>}
      {label && <span>{label}</span>}
      {iconRight && <span>{iconRight}</span>}
    </div>
  );
};

export default CustomButton;
