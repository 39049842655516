import React from "react";
import CustomButton from "../components/CustomButton";
import {
  CaretLineUp,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { footer } from "../variables";

type Props = {};

function Footer({}: Props) {
  return (
    <div className="flex flex-col w-full p-[5%] bg-[#F7C600] md:gap-[70px] sm:gap-[32px] gap-[16px] overflow-hidden">
      <div className="flex sm:flex-row flex-col-reverse sm:justify-between items-center ">
        <img
          src="/assets/icons/footerLogo.svg"
          className=" w-[440px] my-4"
          alt="logo"
        />
        <CustomButton
          variant="black"
          size="xl"
          label="Haut de page"
          iconRight={<CaretLineUp color="#FEFFFF" weight="bold" size={24} />}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      </div>
      <div className="flex justify-center gap-[80px] ">
        {footer?.facebookLink && (
          <a
            className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
            href={footer?.facebookLink}
          >
            <FacebookLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
          </a>
        )}
        {footer?.instagramLink && (
          <a
            className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
            href={footer?.instagramLink}
          >
            <InstagramLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
          </a>
        )}
        {footer?.youtubeLink && (
          <a
            className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
            href={footer?.youtubeLink}
          >
            <YoutubeLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
          </a>
        )}
        {footer?.linkedinLink && (
          <a
            className="flex aspect-square md:w-[56px] md:p-[16px] sm:w-[48px] sm:p-[14px] w-[44px] p-[8px] bg-[#1A1919] rounded-xl mt-1"
            href={footer?.linkedinLink}
          >
            <LinkedinLogo size={"100%"} color={"#FEFFFF"} weight="bold" />
          </a>
        )}
      </div>
    </div>
  );
}

export default Footer;
