import React from "react";
import { partners } from "../variables";

type Props = {};

function PartnersSection({}: Props) {
  return (
    <div className="flex w-full flex-row gap-[5%] items-center justify-between px-[5%] py-[20px]">
      {partners.map((img, index) => (
        <div className="flex-1 flex flex-shrink flex-grow justify-center">
          <img
            src={`/assets/icons/${img}`}
            alt={`partner-logo-${index}`}
            key={index}
          />
        </div>
      ))}
    </div>
  );
}

export default PartnersSection;
