import React from "react";
import { fAndQ, heroSection } from "../variables";
import CustomButton from "../components/CustomButton";
import { WhatsappLogo } from "@phosphor-icons/react";

type Props = {};

function FAndQ({}: Props) {
  return (
    <div className="flex sm:flex-row items-center flex-col w-full p-[5%]   md:gap-[70px] sm:gap-[32px] gap-[16px] overflow-hidden">
      <div className="flex md:flex-row flex-col justify-between md:gap-0 gap-[24px]">
        <div className="flex flex-col w-full md:w-[30%] md:gap-[80px] gap-[24px] ">
          <span className=" text-[#FEFFFF] md:text-[48px] text-[32px] font-space font-bold">
            {fAndQ.title}
          </span>
          {/* <CustomButton
            variant="primary"
            size="xl"
            label={fAndQ.ctaLabel}
            iconLeft={<WhatsappLogo color="#1A1919" weight="bold" size={24} />}
            style={{
              alignSelf: "flex-start",
              flexWrap: "nowrap",
            }}
            onClick={() => (window.location.href = heroSection.whatsappLink)}
          /> */}
        </div>
        <div className="flex flex-col gap-[24px] md:gap-[48px] md:w-[60%] w-full ">
          {fAndQ.items.map((item, index) => (
            <div className="flex flex-col gap-[16px]">
              <span className=" text-[#FEFFFF] md:text-[20px] text-[18px] font-space font-bold">
                {item.q}
              </span>
              <p className=" text-[#FEFFFFCC] md:text-[20px] text-[18px] font-inter ">
                {item.r}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAndQ;
