import React from "react";
import { contactMe, heroSection } from "../variables";
import { ArrowSquareOut, CalendarBlank } from "@phosphor-icons/react";
import CustomButton from "../components/CustomButton";

type Props = {};

function ContactMe({}: Props) {
  return (
    <div className="flex sm:flex-row items-center flex-col w-full p-[5%]  md:gap-[70px] sm:gap-[32px] gap-[16px] overflow-hidden">
      <div className="flex sm:flex-row flex-col p-[8px] md:p-[20px] sm:p-[16px] md:gap-[20px] gap-[16px] rounded-[24px] md:rounded-[44px] sm:rounded-[38px] bg-[#F7C600] w-full ">
        <div className="flex flex-col md:gap-[16px] gap-[12px] md:flex-1 flex-shrink flex-1">
          <h2 className="font-space text-[28px] md:text-[40px] text-[#1A1919] font-bold ">
            {contactMe.title}
          </h2>
          <p className="font-inter text-[16px] md:text-[18px] text-[#1A1919B2] font-bold ">
            {contactMe.desc}
          </p>
        </div>
        <div className="flex w-full sm:w-auto flex-col sm:gap-[16px] gap-[12px] flex-1  p-[8px] md:p-[16px] sm:p-[12px] bg-[#FEFFFF] rounded-[16px] md:rounded-[24px] sm:rounded-[22px] ">
          {contactMe.banners.map(({ label, value, icon }, index) => (
            <div className="border border-[#feffff]/40 flex items-center justify-start rounded-[9px] self-stretch p-[8px] gap-2 bg-[#F3F3F3] ">
              <div className="w-[24px]">{icon}</div>
              <div className="flex flex-col">
                <span
                  className="font-space text-[18px] md:text-[20px] font-semibold text-[#5E5E5E] "
                  style={{ fontWeight: 700 }}
                >
                  {label}
                </span>
                <span
                  className="font-space text-[20px] md:text-[24px] font-bold text-[#5E5E5E] "
                  style={{ fontWeight: 700 }}
                >
                  {value}
                </span>
              </div>
            </div>
          ))}
          <CustomButton
            variant="primary"
            size="xl"
            label={contactMe.ctaLabel}
            iconRight={
              <ArrowSquareOut color="#1A1919" weight="bold" size={24} />
            }
            style={{
              alignSelf: "flex-start",
              width: "100%",
              flexWrap: "nowrap",
            }}
            onClick={() => (window.location.href = heroSection.whatsappLink)}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
